import React, { memo, useEffect } from "react";

import { useCountUp } from "use-count-up";
import { Card } from "@mui/material";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import { DataContext } from "../../dataContext";
import gif from "assets/images/bgs/teste.gif";
import vr from "assets/images/bgs/vr.png";

const CallsRealTimeCard = () => {
  const { callRealtime } = React.useContext(DataContext);
  const { value, reset } = useCountUp({
    isCounting: true,
    start: callRealtime[0],
    end: callRealtime[1],
    duration: 3,
    easing: "easeOutCubic",
  });

  useEffect(() => {
    reset();
  }, [callRealtime, reset]);

  return (
    <Card sx={({ breakpoints }) => ({
      height: "340px",
      py: "32px",
      backgroundImage: `url(${vr})`,
      backgroundSize: "contain",
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#020e2c",
      padding: 0,
      [breakpoints.down("md")]: {
        backgroundPosition: "bottom",
      },
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">

        <VuiBox sx={({ breakpoints }) => ({
          backgroundColor: "transparent",
          height: "340px",
          backgroundImage: `url(${gif})`,
          backgroundSize: "300px",
          backgroundPosition: "320px 0px",
          backgroundRepeat: "no-repeat",
          padding: "22px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          [breakpoints.down("md")]: {
            backgroundImage: "none",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "159px",
          },
        })}

        >
          <VuiBox sx={({ breakpoints }) => ({
            width: "350px", display: "flex",
            height: "200px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            [breakpoints.down("md")]: {
              height: "100px",
            },
          })}>

            <h2 style={{color: '#fff', fontSize: '6rem'}}>{value}</h2>

            <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
              Atendimentos acontecendo agora
            </VuiTypography>
          </VuiBox>
        </VuiBox>

      </VuiBox>
    </Card>
  );
};

export default CallsRealTimeCard;
