import React, { memo } from "react";

import { Card, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { IoCalendar } from "react-icons/io5";

import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiTypography from "components/VuiTypography";

import { DataContext } from "../../dataContext";

const AppointmentsPerPeriodCard = memo(() => {
  const {callForTime} = React.useContext(DataContext);

  return (
    <Card sx={{ height: "100%" }}>
      <VuiBox>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Atendimentos por período
        </VuiTypography>
        <VuiBox display="flex" alignItems="center" mb="40px" />
        <Grid container spacing="50px">
          <Grid  item="true" xs={6} md={6} lg={6} sx={{ height: "155px" }}>
            <Stack
              direction="row"
              spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
              mb="6px"
            >
              <VuiBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoCalendar color="#fff" size="12px" />
              </VuiBox>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                Ontem
              </VuiTypography>
            </Stack>
            <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              {callForTime.yesterday}
            </VuiTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid  item="true" xs={6} md={6} lg={6} sx={{ height: "155px" }}>
            <Stack
              direction="row"
              spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
              mb="6px"
            >
              <VuiBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoCalendar color="#fff" size="12px" />
              </VuiBox>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                Semana passada
              </VuiTypography>
            </Stack>
            <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              {callForTime.lastWeek}
            </VuiTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid  item="true" xs={6} md={6} lg={6} sx={{ height: "155px" }}>
            <Stack
              direction="row"
              spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
              mb="6px"
            >
              <VuiBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoCalendar color="#fff" size="12px" />
              </VuiBox>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                Mês passado
              </VuiTypography>
            </Stack>
            <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              {callForTime.lastMonth}
            </VuiTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid  item="true" xs={6} md={6} lg={6} sx={{ height: "155px" }}>
            <Stack
              direction="row"
              spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
              mb="6px"
            >
              <VuiBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoCalendar color="#fff" size="12px" />
              </VuiBox>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                Ano passado
              </VuiTypography>
            </Stack>
            <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              {callForTime.lastYear}
            </VuiTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
});

export default AppointmentsPerPeriodCard;
