import { useState } from "react";

// react-router-dom components
import { useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import VuiAlert from "components/VuiAlert";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import hybrid from "assets/images/logos/HYBRID-2.png";
import { clearLogued, setLogued } from "../../../helper";

function SignIn() {
  const [errorLogin, setErrorLogin] = useState(false);
  const [inputs, setInputs] = useState({
    login: '',
    password: ''
  });
  const history = useHistory()

  const login = ()=>{
    setErrorLogin(false)
    if(inputs.login.toLowerCase() === 'hybrid' && inputs.password === '2323'){
      setErrorLogin(false)
      setLogued()
      history.push('/dashboard')
    }else{
      setErrorLogin(true)
      clearLogued()
    }
  }

  const handleLoginChange = (event) => {
    setInputs({...inputs, login: event.target.value});
  };
  const handlePasswordChange = (event) => {
    setInputs({...inputs, password: event.target.value});
  };

  return (
    <CoverLayout
      title="Hybrid Dashboard"
      color="white"
      description="Entre"
      premotto=""
      motto=""
      image={hybrid}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Login
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              value={inputs.login}
              type="text"
              placeholder="Login..."
              fontWeight="500"
              id="login"
              name="login"
              onChange={handleLoginChange}
              error={errorLogin}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Senha
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Senha..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={inputs.password}
              id="password"
              name="password"
              onChange={handlePasswordChange}
              error={errorLogin}
            />
          </GradientBorder>
        </VuiBox>
        {errorLogin && (
          <VuiBox mt={4} mb={1}>
            <VuiAlert color="error" dismissible>Login ou senha incorretos.</VuiAlert>
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
            <VuiButton onClick={()=>login()} color="info" fullWidth>
              Entrar
            </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
