import React, { memo } from "react";

// @mui material components
import Card from "@mui/material/Card";


// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Billing page components
import Transaction from "layouts/dashboard/components/Transaction";
import { DataContext } from "../../dataContext";

const Transactions = memo(function() {

  const { logs } = React.useContext(DataContext);

  return (
    <Card sx={{ height: "100%", maxHeight: "635px", overflow: "hidden" }}>
      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="18px"
        sx={({ breakpoints }) => ({
          [breakpoints.down("lg")]: {
            flexDirection: "column",
          },
        })}
      >
        <VuiTypography
          variant="lg"
          fontWeight="bold"
          textTransform="capitalize"
          color="white"
          sx={({ breakpoints }) => ({
            [breakpoints.only("sm")]: {
              mb: "6px",
            },
          })}
        >
          Atendimentos superados
        </VuiTypography>
      </VuiBox>
      <VuiBox>

        <VuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {logs && logs.map(item => (
            <Transaction
              key={item.id}
              item={item}
            />
          ))}
        </VuiBox>

      </VuiBox>
    </Card>
  );
});
export default Transactions;
