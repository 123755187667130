import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { randomFloat } from "../../helper";


let LOGS_DATA = []
let REALTIME_DATA = [0, 20]

const defaultMonthCalls = [
  { date: "01/02", value: 200 },
  { date: "8/02", value: 300 },
  { date: "16/02", value: 100 },
  { date: "24/02", value: 123 },
  { date: "28/02", value: 321 },
];

export const DataContext = React.createContext({
  logs: [],
  callRealtime: REALTIME_DATA,
  counts: {
    accounts: 0,
    users: 0,
    overcome: 0,
    overcomeAmount: 0,
  },
  callForTime: {
    yesterday: 0,
    lastWeek: 0,
    lastMonth: 0,
    lastYear: 0,
  },
  monthCalls: [],
});

export const DataProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const [callRealtime, setCallRealtime] = useState(REALTIME_DATA);
  const [monthCalls] = useState(defaultMonthCalls);
  const [callForTime, setCallForTime] = useState({
    yesterday: 0,
    lastWeek: 0,
    lastMonth: 0,
    lastYear: 0,
  });
  const [counts, setCounts] = useState({
    accounts: 0,
    users: 0,
    overcome: 0,
    overcomeAmount: 0,
  });

  useEffect(() => {
    console.log("AAAAAAAAAAAAAA");
    const interval = setInterval(() => {

      console.log("BBBBBBBBBB");
      const isOvercome = Math.random() < 0.7;
      const amount = isOvercome ? randomFloat(200, 1500) : 0;
      let realmTime =  Math.floor(Math.random() * 15);

      if(isOvercome){
        realmTime = REALTIME_DATA[1] + realmTime;
      }else{
        realmTime = REALTIME_DATA[1] - realmTime;
        if(realmTime < 0) realmTime = 0;
      }

      const newLogs = [
        {
          id: `#${nanoid(10).toUpperCase()}`,
          date: new Date().toLocaleString("pt-BR"),
          amount: +amount.toFixed(2),
          overcome: isOvercome,
        },
        ...LOGS_DATA,
      ];

      LOGS_DATA= newLogs;

      const overcomeList = newLogs.filter(log => log.overcome);

      REALTIME_DATA = [REALTIME_DATA[1], +realmTime.toFixed(0)]
      console.log("[callRealtime]",  REALTIME_DATA);
      setCallRealtime(REALTIME_DATA);

      setLogs(newLogs);

      setCounts({
        accounts: 53,
        users: 120,
        overcome: overcomeList.length,
        overcomeAmount: overcomeList.reduce((acc, log) => acc + parseFloat(log.amount), 0).toFixed(2),
      });

      setCallForTime({
        lastMonth: 1500,
        lastWeek: 287,
        lastYear: 0,
        yesterday: 35,
      });
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <DataContext.Provider value={{ logs, callRealtime, counts, monthCalls, callForTime }}>
      {children}
    </DataContext.Provider>
  );
};
