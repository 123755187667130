

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// Logo
import logo from "assets/images/logos/logo-white.png";

function FooterDashboard() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      component="footer"
      py={2}
      pb={0}
    >
      <VuiBox item={true} xs={12} sx={{ textAlign: "center", gap: "20px", width: '100%' }}  display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              direction="row">
        <img src={logo} alt="Hybrid" />
        <VuiTypography
          variant="button"
          sx={{ textAlign: "center", fontWeight: "400 !important" }}
          color="white"
        >
        <span>  @ 2023</span>
        <span> Tecnologia Óptica de Superação</span>
        </VuiTypography>
      </VuiBox>
      <VuiBox item={true} xs={10}>
        {/*<VuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>*/}
        {/*  <VuiBox mr={{ xs: "20px", lg: "46px" }}>*/}
        {/*    <VuiTypography*/}
        {/*      component="a"*/}
        {/*      href="https://www.creative-tim.com/templates"*/}
        {/*      variant="body2"*/}
        {/*      color="white"*/}
        {/*    >*/}
        {/*      Marketplace*/}
        {/*    </VuiTypography>*/}
        {/*  </VuiBox>*/}
        {/*  <VuiBox mr={{ xs: "20px", lg: "46px" }}>*/}
        {/*    <VuiTypography*/}
        {/*      component="a"*/}
        {/*      href="https://www.creative-tim.com/blog/"*/}
        {/*      variant="body2"*/}
        {/*      color="white"*/}
        {/*    >*/}
        {/*      Blog*/}
        {/*    </VuiTypography>*/}
        {/*  </VuiBox>*/}
        {/*  <VuiBox>*/}
        {/*    <VuiTypography*/}
        {/*      component="a"*/}
        {/*      href="https://www.creative-tim.com/license"*/}
        {/*      variant="body2"*/}
        {/*      color="white"*/}
        {/*    >*/}
        {/*      License*/}
        {/*    </VuiTypography>*/}
        {/*  </VuiBox>*/}
        {/*</VuiBox>*/}
      </VuiBox>
    </VuiBox>
  );
}

export default FooterDashboard;
