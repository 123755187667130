import React  from "react";

import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";

import vr from "assets/images/billing-background-card.png";
const VideoCard = ( )=> {
  return (
    <Card sx={() => ({
      py: "32px",
      backgroundImage: `url(${vr})`,
      backgroundSize: "initial",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#020e2c",
      padding: 0
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox style={{position: 'relative', paddingBottom: '56.25%'}}>
      <iframe src="https://www.youtube.com/embed/WMhFgNJRsMg?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&modestbranding=1&playlist=WMhFgNJRsMg&disablekb=0&modestbranding=1" title="Hybrid"
        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
        web-share" color="#0075FF" allowFullScreen  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
      </iframe>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default VideoCard;
