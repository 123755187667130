import React, { memo, useMemo } from "react";

import { Card } from "@mui/material";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import { DataContext } from "../../dataContext";
import LineChart from "../../../../examples/Charts/LineCharts/LineChart";
import { lineChartOptionsDashboard } from "../../data/lineChartOptions";

const LastMonthCard = memo(() => {
  const {monthCalls} = React.useContext(DataContext);

  const values = useMemo(()=> monthCalls.map(dt => dt.value))
  const labels = useMemo(()=> monthCalls.map(dt => dt.date))

  const valuesMonthCalls = [
    {
      name: "Atendimentos",
      data: values,
    },
  ]

  const optionsWithLabels = {
    ...lineChartOptionsDashboard,
    xaxis: {
      ...lineChartOptionsDashboard.xaxis,
      categories: labels,
    },
  }

  return (
    <Card>
      <VuiBox sx={{ height: "100%" }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Atendimentos nos últimos 30 dias
        </VuiTypography>
        <VuiBox display="flex" alignItems="center" mb="40px">
          <VuiTypography variant="button" color="success" fontWeight="bold">
            +5% a mais{" "}
            <VuiTypography variant="button" color="text" fontWeight="regular">
              que no mesmo período de 2022
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox sx={{ height: "310px" }}>
          <LineChart
            lineChartData={valuesMonthCalls}
            lineChartOptions={optionsWithLabels}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
});

export default LastMonthCard;
