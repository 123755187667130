import React, { memo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";


const Transaction = memo( function ({ item }) {

  const color = item.overcome? 'success': 'text';

  return (
    <VuiBox key={item.id} component="li" py={1} pr={2} mb={1}>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center">
        <VuiBox display="flex" alignItems="center">
          <VuiBox mr={2}>
            <VuiButton
              variant="outlined"
              color={color}
              sx={{ fontWeight: "bold", width: "35px", height: "35px" }}
              size="small"
              iconOnly
              circular
            >
              <Icon sx={{ fontWeight: "bold" }}>{item.overcome? 'arrow_upward': 'priority_high'}</Icon>
            </VuiButton>
          </VuiBox>
          <VuiBox display="flex" flexDirection="column">
            <VuiTypography variant="button" color="white" fontWeight="medium" gutterBottom>
              {item.id}
            </VuiTypography>
            <VuiTypography variant="caption" color="text">
              {item.date.substr(0, 16)}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          {item.overcome && (
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
              sx={({ breakpoints }) => ({
                [breakpoints.down("lg")]: {
                  minWidth: "75px",
                  ml: "12px",
                },
              })}
            >
              Superado!
            </VuiTypography>
          )}

          <VuiTypography
            variant="button"
            color={color}
            fontWeight="medium"
            sx={({ breakpoints }) => ({
              [breakpoints.down("lg")]: {
                minWidth: "75px",
                ml: "12px",
              },
            })}
          >
            R$ {item.amount}
          </VuiTypography>
        </VuiBox>

      </VuiBox>
    </VuiBox>
  );
})

// Typechecking props of the Transaction
Transaction.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Transaction;
