import React, { memo } from "react";

import { Card } from "@mui/material";

import hybrid from "assets/images/logos/HYBRID-2.png";
const HybridCard = memo(() => {
  return (
    <Card sx={({ breakpoints }) => ({
      height: "340px",
      width: "100%",
      py: "32px",
      backgroundImage: `url(${hybrid})`,
      backgroundSize: "100%",
      backgroundPosition: "center -16px",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#020e2c",
      [breakpoints.down("lg")]: {
        backgroundPosition: "center 40%",
      },
      [breakpoints.down("md")]: {
        height: "242px",
      },
    })}>

    </Card>
  );
});

export default HybridCard;
