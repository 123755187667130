import React  from "react";
import { useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FooterDashboard from "examples/Footer";


// Dashboard layout components
import CallsRealTimeCard from "layouts/dashboard/components/CallsRealTimeCard";
import HybridCard from "layouts/dashboard/components/HybridCard";
import Transactions from "./components/Transactions";
import VideoCard from "./components/VideoCard";
import LastMonthCard from "./components/LastMonthCard";
import AppointmentsPerPeriodCard from "./components/AppointmentsPerPeriodCard";
import StatisticsGrid from "./components/StatisticsGrid";

import { checkLogued } from "../../helper";


function Dashboard() {
  const history = useHistory();

  if (!checkLogued()) {
    history.push("/login");
  }

  return (
      <DashboardLayout>

        <VuiBox py={3}>

          <VuiBox mb={3}>
            <Grid container spacing="18px">

              <Grid  item="true" xs={12} lg={6} xl={4}>
                <HybridCard />
              </Grid>

              <Grid  item="true" xs={12} lg={12} xl={8}>
                <CallsRealTimeCard />
              </Grid>

            </Grid>
          </VuiBox>

          <VuiBox mb={3}>
            <Grid container spacing={3}>

              <Grid  item="true" xs={12} lg={6} xl={7}>
                <LastMonthCard />
              </Grid>

              <Grid  item="true" xs={12} lg={6} xl={5}>
                <AppointmentsPerPeriodCard />
              </Grid>

            </Grid>
          </VuiBox>

          <VuiBox mb={3}>
            <Grid container spacing="18px">
              <Grid  item="true" xs={12} lg={6} xl={6}>
                <StatisticsGrid />

                <VideoCard />
              </Grid>

              <Grid  item="true" xs={12} md={6} lg={6}>
                <Transactions />
              </Grid>

            </Grid>
          </VuiBox>
        </VuiBox>

        <FooterDashboard />

      </DashboardLayout>
  );
}

export default Dashboard;
