const authKey = 'HIBRYD_AUTH';

export const setLogued = ()=> {
  localStorage.setItem(authKey,'true');
}
export const clearLogued = ()=> {
  localStorage.removeItem(authKey);
}

export const checkLogued = ()=> {
 if(localStorage.getItem(authKey) === 'true'){
   return true;
 }else{
   clearLogued()
   return false;
 }
}

export const randomFloat = (min, max) => {
  const value =  Math.random() * (max - min) + min
  const decimal = Math.random() * (100 - 1) + 1;
  return value + decimal;
}
