import React, { memo } from "react";

import Grid from "@mui/material/Grid";
import { IoCash, IoHome, IoPeople, IoRocket } from "react-icons/io5";

import VuiBox from "components/VuiBox";

import { DataContext } from "../../dataContext";
import MiniStatisticsCard from "../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";

const StatisticsGrid = memo(() => {
  const {counts} = React.useContext(DataContext);

  return (
   <>
     <VuiBox mb={3}>
       <Grid container spacing={3}>
         <Grid item="true" xs={12} md={6} xl={6}>
           <MiniStatisticsCard
             title={{ text: "Contas", fontWeight: "regular" }}
             count={counts.accounts}
             percentage={{color: "info", texte: ''}}
             icon={{ color: "info", component: <IoHome size="22px" color="white" /> }}
           />
         </Grid>
         <Grid item="true" xs={12} md={6} xl={6}>
           <MiniStatisticsCard
             title={{ text: "Usuários" }}
             count={counts.users}
             percentage={{color: "info", texte: ''}}
             icon={{ color: "info", component: <IoPeople size="22px" color="white" /> }}
           />
         </Grid>
       </Grid>
     </VuiBox>
     <VuiBox mb={3}>
       <Grid container spacing={3}>
         <Grid  item="true" xs={12} md={6} xl={6}>
           <MiniStatisticsCard
             title={{ text: "Superados" }}
             count={counts.overcome}
             percentage={{color: "info", texte: ''}}
             icon={{ color: "info", component: <IoRocket size="22px" color="white" /> }}
           />
         </Grid>
         <Grid  item="true" xs={12} md={6} xl={6}>
           <MiniStatisticsCard
             title={{ text: "Total superado" }}
             count={`+ R$ ${counts.overcomeAmount}`}
             percentage={{color: "info", texte: ''}}
             icon={{ color: "info", component: <IoCash size="20px" color="white" /> }}
           />
         </Grid>
       </Grid>
     </VuiBox>
   </>
  );
});

export default StatisticsGrid;
